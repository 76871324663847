import React from 'react'
import { createRoot } from 'react-dom/client'
//import { Template } from "./../Template";
//import { VideoEdit } from "./../VideoEdit";
//import { VideoNew } from "./../VideoNew";
 
// used for rendering the composition in the live app
//registerRoot(RemotionRoot);

const attachReact = (el, cb) => {
  const node = document.getElementById(el)
  if (node) { cb(node) }
}

document.addEventListener('turbo:load', () => {
  //attachReact('react-template-form', function (node) {
    //console.log('loading react-template-form')
    //const root = createRoot(node)
    //root.render(<Template {...JSON.parse(node.getAttribute('data')) } />)
  //})
  //attachReact('react-video-form', function (node) {
    //const root = createRoot(node)
    //root.render(<VideoEdit {...JSON.parse(node.getAttribute('data')) } />)
  //})
  //attachReact('react-video-new', function (node) {
    //const root = createRoot(node)
    //console.log('loading react-video-new from turbo:load')
    //root.render(<VideoNew {...JSON.parse(node.getAttribute('data')) } />)
  //})
})
